import React from 'react';
import { toolActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { RenderPlatforms } from '../common';
import { GenericListConfig, ItemType } from '../../types';
import { tool } from '../../defaultValues';
import Form from './Form';

const config: GenericListConfig = {
  path: 'tools',
  stateKey: 'tool',
  newButtonLabel: 'Nova Ferramenta',
  headerLabel: 'Editar Ferramenta',
  itemsToShow: [
    { name: 'order', title: 'Ordem', type: ItemType.number },
    { name: 'platforms', title: 'Plataforma', render: RenderPlatforms },
    { name: 'name', title: 'Nome', type: ItemType.text },
    { name: 'link', title: 'Link', type: ItemType.text },
    { name: 'description', title: 'Texto', type: ItemType.text },
    { name: 'image', title: 'Imagem', type: ItemType.image },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={toolActions}
      defaultValues={tool}
      Form={Form}
    />
  );
};

export default Index;
