import { MediaItemAdmin } from '../types';
import { commonProps } from './commonProps';

export const mediaItem: Omit<MediaItemAdmin, '_id'> = {
  ...commonProps,
  title: '',
  link: '',
  description: '',
  image: '',
  video: '',
  text: '',
  tags: '',
  order: 0,
};
