import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required, validLink } from '../../helpers/validations';
import { TextInput, FileInput, SelectInput, CompanySelect } from '../common';
import { useSelector } from 'react-redux';
import { roles, hasRole } from '../../helpers/roles';
import GenericForm from '../_generic_crud/GenericForm';
import { AuthAdminState, BackendUserAdmin, StoreState } from '../../types';

interface FormProps {
  initialValues: Partial<BackendUserAdmin>;
  fetching: boolean;
  success: boolean;
  requestError: any;
  onSave: (data: any) => any;
  path: string;
}

const Form: React.FC<FormProps> = (props) => {
  const form = useForm<BackendUserAdmin>({
    defaultValues: props.initialValues,
  });
  const { control, setValue } = form;
  const { initialValues } = props;

  const { userLoggedIn } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const isDisabled = !hasRole(userLoggedIn, initialValues.role || 'admin');
  const isRolesDisabled = isDisabled || initialValues._id === userLoggedIn.sub;
  const allowedRoles = roles
    .filter((role) =>
      isRolesDisabled
        ? initialValues.role === role
        : hasRole(userLoggedIn, role),
    )
    .map((role) => ({ value: role, label: role }));

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Nome"
            input={field}
            meta={fieldState}
            className="required"
            disabled={isDisabled}
          />
        )}
        rules={required}
      />
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="email"
            label="Email"
            input={field}
            meta={fieldState}
            className="required"
            disabled={isDisabled}
          />
        )}
        rules={required}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Telefone"
            input={field}
            meta={fieldState}
            disabled={isDisabled}
          />
        )}
      />
      <Controller
        name="picture"
        //type="file"
        control={control}
        render={({ field, fieldState }) => (
          <FileInput
            label="Foto"
            input={field}
            meta={fieldState}
            setValue={setValue}
            disabled={isDisabled}
          />
        )}
        rules={validLink}
      />

      <Controller
        name="role"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            label="Acesso"
            input={field}
            meta={fieldState}
            className="required"
            disabled={isRolesDisabled}
            options={allowedRoles}
          />
        )}
        rules={required}
      />

      <Controller
        name="companies"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            label="Clientes Corporativos"
            input={field}
            meta={fieldState}
            multiple={true}
            className="required"
          />
        )}
        rules={required}
      />
    </GenericForm>
  );
};

export default Form;
