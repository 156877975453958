import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { CheckBoxInput } from './CheckBoxInput';

interface PlatformCheckboxesProps {
  control: Control<any>;
}

export const PlatformCheckboxes: React.FC<PlatformCheckboxesProps> = ({
  control,
}) => {
  return (
    <div>
      <Controller
        name="platforms[ios]"
        control={control}
        render={({ field, fieldState }) => (
          <CheckBoxInput label="iOS" input={field} meta={fieldState} />
        )}
        //normalize={Boolean}
      />
      <Controller
        name="platforms[android]"
        control={control}
        render={({ field, fieldState }) => (
          <CheckBoxInput label="Android" input={field} meta={fieldState} />
        )}
        //normalize={Boolean}
      />
      <Controller
        name="platforms[web]"
        control={control}
        render={({ field, fieldState }) => (
          <CheckBoxInput label="Web" input={field} meta={fieldState} />
        )}
        //normalize={Boolean}
      />
    </div>
  );
};
