import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logActions } from '../../../actions';
import LogList from '../../logs/List';
import Tabs from '../tabs';
import { LogAdminState, StoreState } from '../../../types';
import { useParams } from 'react-router-dom';

const UsersLogsIndex: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const {
    all: logs,
    fetching,
    count,
    page,
    requestError,
  } = useSelector<StoreState, LogAdminState>((state) => state.log);

  const dispatch = useDispatch();

  const fetchItems = useCallback(
    (page = 0) => {
      dispatch(logActions.fetchAll(page, { backend_user: id }));
    },
    [dispatch, id],
  );

  useEffect(() => {
    fetchItems(0);
  }, [fetchItems]);

  return (
    <div className="logs-index">
      <Tabs modelUrl="backend_users" parentId={id} />
      <LogList
        error={requestError}
        items={logs}
        count={count}
        fetching={fetching}
        loadMore={() => fetchItems(page + 1)}
      />
    </div>
  );
};

export default UsersLogsIndex;
