import { Route, Routes } from 'react-router-dom';
import RequireAuth from '../../hoc/require_auth';
import RequireGuest from '../../hoc/require_guest';
import Auth from './Auth';
import AuthLink from './AuthLink';
import AcceptToken from './AcceptToken';
import Signout from './Signout';

export const AuthRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireGuest>
            <Auth />
          </RequireGuest>
        }
      />
      <Route
        path="/link"
        element={
          <RequireGuest>
            <AuthLink />
          </RequireGuest>
        }
      />
      <Route
        path="/acceptToken"
        element={
          <RequireGuest>
            <AcceptToken />
          </RequireGuest>
        }
      />
      <Route
        path="/signout"
        element={
          <RequireAuth>
            <Signout />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AuthRoutes;
