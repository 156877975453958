import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../helpers/roles';
import { sections } from './sections';
import { AuthAdminState, StoreState } from '../../../types';
import { RouterLink } from '../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Form, InputGroup, Nav } from 'react-bootstrap';

const Sidebar: React.FC = () => {
  const [search, setSearch] = useState('');

  const { userLoggedIn: user } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const getAuthorizedSections = () => {
    return sections
      .filter((section) => hasRole(user, section.role || 'user'))
      .map((section) => ({
        ...section, // don't mutate
        links: section.links.filter((link) =>
          link.label.match(new RegExp(search, 'gi')),
        ),
      }))
      .filter((section) => section.links.length);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const cancelSearch = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    setSearch('');
  };

  const renderSearch = () => {
    return (
      <InputGroup className="input-search">
        <Form.Control
          type="text"
          name="searchTerm"
          placeholder="Buscar Seção"
          onChange={handleSearch}
          value={search}
        />
        <InputGroup.Text>
          {search ? (
            <FontAwesomeIcon icon={faRemove} onClick={cancelSearch} />
          ) : (
            <FontAwesomeIcon icon={faSearch} />
          )}
        </InputGroup.Text>
      </InputGroup>
    );
  };

  const renderSections = () => {
    return getAuthorizedSections().map((section, key) => [
      <div className="box-title-section" key={`section-${key}`}>
        <FontAwesomeIcon icon={section.icon} size="sm" />
        <span>{section.name}</span>
      </div>,
      <ul className="nav" key={`links-${key}`}>
        {section.links.map((link) => (
          <RouterLink
            to={{ pathname: link.path, state: { fromSidebar: true } }}
            key={link.path}
          >
            {link.label}
          </RouterLink>
        ))}
      </ul>,
    ]);
  };

  return (
    <Nav className="col-md-2 sidebar">
      <div
        className="navbar navbar-inverse navbar-fixed-left align-items-start"
        role="navigation"
      >
        {/* <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".sidebar-navbar-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <span className="visible-xs navbar-brand">CDF Digital</span>
        </div> */}
        <div className="navbar-collapse fixme_collapse sidebar-navbar-collapse">
          <div className="navbar-form">{renderSearch()}</div>
          {renderSections()}
        </div>
      </div>
    </Nav>
  );
};

export default Sidebar;
