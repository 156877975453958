import React from 'react';
import { Nav } from 'react-bootstrap';
import { RouterLink } from '../common';

interface TabsProps {
  modelUrl: string;
  parentId?: string;
}

const Tabs: React.FC<TabsProps> = ({ modelUrl, parentId }) => {
  return (
    <Nav variant="tabs" activeKey="1" className="add-margin-bottom">
      <RouterLink eventKey="1" to={`/${modelUrl}/${parentId}/edit`}>
        Editar Usuário
      </RouterLink>
      <RouterLink eventKey="2" to={`/${modelUrl}/${parentId}/logs`}>
        Logs
      </RouterLink>
    </Nav>
  );
};

export default Tabs;
