import React from 'react';
import { introductionActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { RenderPlatforms } from '../common';
import { GenericListConfig, ItemType } from '../../types';
import Form from './Form';
import { introduction } from '../../defaultValues';

const config: GenericListConfig = {
  path: 'introductions',
  stateKey: 'introduction',
  newButtonLabel: 'Nova Introdução',
  headerLabel: 'Editar Introdução',
  itemsToShow: [
    { name: 'order', title: 'Ordem', type: ItemType.number },
    { name: 'platforms', title: 'Plataforma', render: RenderPlatforms },
    { name: 'text', title: 'Texto', type: ItemType.text },
    { name: 'background', title: 'Background', type: ItemType.image },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={introductionActions}
      defaultValues={introduction}
      Form={Form}
    />
  );
};

export default Index;
