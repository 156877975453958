import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { hasRole } from '../helpers/roles';
import { AuthAdminState, StoreState } from '../types';

interface RequireAuthProps {
  children: React.ReactNode;
  role?: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  role = 'user',
}) => {
  const { authenticated, userLoggedIn } = useSelector<
    StoreState,
    AuthAdminState
  >((state) => state.auth);

  if (!authenticated) {
    return <Navigate to="/auth" />;
  }

  if (!hasRole(userLoggedIn, role)) {
    return <Alert variant="danger">Acesso negado</Alert>;
  }

  return <>{children}</>;
};

export default RequireAuth;
