import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  required,
  requiredValidLink,
  validLink,
} from '../../helpers/validations';
import {
  TextInput,
  SelectInput,
  FileInput,
  CompanySelect,
  PlatformCheckboxes,
} from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { HomeLinkAdmin } from '../../types';

const routes = [
  'News',
  'ServiceChannels',
  'Schedule',
  'Tools',
  'StoreLocation',
  'Account',
  'ToolsItem',
];

const routeSlugs = [
  'tool_family',
  'tool_safe_navigation',
  'tool_cloud',
  'tool_lucky_number',
  'tool_nutrition',
  'tool_discounts',
  'tool_fitness',
  'tool_safety',
];

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<HomeLinkAdmin>({
    defaultValues: props.initialValues,
  });
  const { control, setValue } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Título"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="subtitle"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Subtítulo"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Descrição"
            input={field}
            meta={fieldState}
          />
        )}
      />
      <Controller
        name="image"
        control={control}
        render={({ field, fieldState }) => (
          <FileInput
            //type="text"
            label="Imagem"
            input={field}
            meta={fieldState}
            setValue={setValue}
            className="required"
          />
        )}
        rules={requiredValidLink}
      />
      <Controller
        name="imageDesktop"
        control={control}
        render={({ field, fieldState }) => (
          <FileInput
            //type="text"
            label="Imagem Desktop"
            input={field}
            meta={fieldState}
            setValue={setValue}
          />
        )}
        rules={validLink}
      />
      <Controller
        name="route"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            label="Rota"
            input={field}
            meta={fieldState}
            className="required"
            options={routes.map((type) => ({ value: type, label: type }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="routeSlug"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            label="Rota Slug"
            input={field}
            meta={fieldState}
            className="required"
            options={routeSlugs.map((type) => ({ value: type, label: type }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="textPosition"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            label="Posição do Texto"
            input={field}
            meta={fieldState}
            className="required"
            options={['left', 'right'].map((type) => ({
              value: type,
              label: type,
            }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="order"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label="Ordem"
            type="number"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="company"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            label="Cliente Corporativo"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />

      <PlatformCheckboxes control={control} />
    </GenericForm>
  );
};

export default Form;
