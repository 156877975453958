import React from 'react';

interface ListActionsProps {
  children: any;
  count: number;
}

export const ListActions: React.FC<ListActionsProps> = (props) => {
  const { children, count } = props;

  const inputs = children.length ? [...children] : [children];
  const search = inputs.pop();
  const s = count === 1 ? '' : 's';

  return (
    <div className="row list-actions">
      <div className="col-md-8">{inputs}</div>
      <div className="col-md-2 text-right">
        {count} registro{s} encontrado{s}
      </div>
      <div className="col-md-2 text-right">{search}</div>
    </div>
  );
};
