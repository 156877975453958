import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ErrorOrHelp } from './ErrorOrHelp';

interface CheckBoxInputProps {
  input: ControllerRenderProps<any, any>;
  //fieldValue: any;
  label: string;
  checked?: boolean;
  meta: ControllerFieldState;
}

export const CheckBoxInput = ({
  input,
  //fieldValue,
  label,
  checked,
  meta: { isTouched, error },
}: CheckBoxInputProps) => {
  // if (fieldValue) {
  //   input.value = fieldValue;
  // }

  return (
    <FormGroup>
      <Form.Check
        {...input}
        placeholder={label}
        type="checkbox"
        checked={input.value}
        label={label}
      />
      <ErrorOrHelp error={error} />
    </FormGroup>
  );
};
