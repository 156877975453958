import React from 'react';

interface EmptyValuesProps {
  message?: string;
}

const EmptyValues: React.FC<EmptyValuesProps> = ({ message }) => {
  return (
    <div className="text-center add-margin-bottom">
      {message || 'No Records found.'}
    </div>
  );
};

export { EmptyValues };
