import axios from 'axios';
import { axiosErrorMessage } from '../../helpers/index';
import {
  AUTH_SIGNIN,
  AUTH_SIGNOUT,
  AUTH_CURRENTLY_LOGGED_IN,
  AUTH_ERRORS,
  AUTH_PASSWORDLESS,
  AUTH_PASSWORDLESS_ERRORS,
  AUTH_PASSWORDLESS_SENDING,
  AUTH_PASSWORDLESS_LOADING,
  AUTH_PASSWORDLESS_NOT_LOADING,
  AUTH_SET_CURRENT_COMPANY,
} from './_types';

const axiosSignin = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function signin(token: string, dispatch: Function) {
  localStorage.setItem('admin-token', token);

  dispatch(signinJwt(token));

  dispatch({
    type: AUTH_SIGNIN,
  });
  // dispatch(push('/')); // TODO: Check if is necessary
}

export function signinJwt(jwt: string) {
  const payloadString = jwt.split('.')[1];
  const jwtPayload = JSON.parse(atob(payloadString));

  return {
    type: AUTH_CURRENTLY_LOGGED_IN,
    payload: jwtPayload,
  };
}

export function signinPasswordless(email: string) {
  return function (dispatch: Function) {
    dispatch({
      type: AUTH_PASSWORDLESS_SENDING,
    });

    localStorage.setItem('auth-email', email);

    axiosSignin
      .post('/requestToken', { email })
      .then((response) => {
        dispatch({
          type: AUTH_PASSWORDLESS,
        });
      })
      .catch((err) => {
        dispatch({
          type: AUTH_PASSWORDLESS_ERRORS,
          payload: axiosErrorMessage(err),
        });
      });
  };
}

export function signinGoogle({ code }: { code: string }) {
  return function (dispatch: Function) {
    axiosSignin
      .post('/signin/google', { idToken: code })
      .then((response) => signin(response.data.token, dispatch))
      .catch((err) => {
        dispatch({
          type: AUTH_ERRORS,
          payload: axiosErrorMessage(err),
        });
      });
  };
}

export function acceptToken(token: string, uid: string) {
  return function (dispatch: Function) {
    dispatch(passwordlessLoading());

    axiosSignin
      .post('/acceptToken', { token, uid })
      .then((response) => {
        signin(response.data.token, dispatch);
        dispatch(passwordlessNotLoading());
      })
      .catch((err) => {
        dispatch(passwordlessNotLoading());
        dispatch({
          type: AUTH_PASSWORDLESS_ERRORS,
          payload: axiosErrorMessage(err),
        });
      }); // TODO Handle errors
  };
}

export function signout(message: string | null = null) {
  return function (dispatch: Function) {
    localStorage.removeItem('admin-token');
    dispatch({
      type: AUTH_SIGNOUT,
      payload: message,
    });
    //dispatch(push('/auth')); // TODO: Check if is necessary
  };
}

function passwordlessLoading() {
  return {
    type: AUTH_PASSWORDLESS_LOADING,
  };
}

function passwordlessNotLoading() {
  return {
    type: AUTH_PASSWORDLESS_NOT_LOADING,
  };
}

export function setCurrentCompany(id: string) {
  return {
    type: AUTH_SET_CURRENT_COMPANY,
    payload: id,
  };
}
