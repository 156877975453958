import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import LogList from '../logs/List';
import { logActions } from '../../actions';
import { LogAdminState, StoreState } from '../../types';

interface ListTableLogsProps {
  documentId: string;
  closeModal: () => void;
}

export const ListTableLogs: React.FC<ListTableLogsProps> = ({
  documentId,
  closeModal,
}) => {
  const [page, setPage] = useState<number>(0);

  const dispatch = useDispatch();

  const {
    all,
    count,
    fetching,
    //page,
  } = useSelector<StoreState, LogAdminState>((state) => state.log);

  useEffect(() => {
    dispatch(logActions.fetchAll(page, { doc: documentId }));
  }, [dispatch, page, documentId]);

  return (
    <Modal
      show={documentId !== null}
      onHide={closeModal}
      aria-labelledby="contained-modal-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">Logs</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'scroll', height: '85vh' }}>
        <LogList
          items={all}
          count={count}
          fetching={fetching}
          loadMore={() => setPage(page + 1)}
          fromModal={true}
        />
      </Modal.Body>
    </Modal>
  );
};
