import React from 'react';
//import PropTypes from 'prop-types';
import { Routes, Route, Navigate } from 'react-router-dom';

import HomeRoutes from '../components/app/Home';

import { NoMatch } from '../components/common';

// RequireAuth HOC
import RequireAuth from '../hoc/require_auth';
import AuthRoutes from '../components/auth';
import BackendUsersRoutes from '../components/backend_users';
import CompaniesRoutes from '../components/companies';

import LogRoutes from '../components/logs';
import { CrudRoutes } from './CrudRoutes';

interface RoutesProps {
  history: any;
}

const AppRoutes: React.FC<RoutesProps> = (props) => {
  // static propTypes = {
  //   history: PropTypes.object.isRequired,
  // };
  // historyUnlisten: any;

  // useEffect(() => {
  //   const historyUnlisten = props.history.listen(() => window.scrollTo(0, 0));
  //   return () => {
  //     historyUnlisten();
  //   };
  // }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <HomeRoutes />
          </RequireAuth>
        }
      />
      <Route
        path="/acceptToken"
        element={<Navigate to={`/auth/acceptToken${window.location.search}`} />}
      />
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route
        path="/*"
        element={
          <RequireAuth role="user">
            <CrudRoutes />
          </RequireAuth>
        }
      />
      <Route
        path="/backend_users/*"
        element={
          <RequireAuth role="admin">
            <BackendUsersRoutes />
          </RequireAuth>
        }
      />
      <Route
        path="/companies/*"
        element={
          <RequireAuth role="sa">
            <CompaniesRoutes />
          </RequireAuth>
        }
      />
      <Route
        path="/logs"
        element={
          <RequireAuth role="sa">
            <LogRoutes />
          </RequireAuth>
        }
      />
      <Route element={<NoMatch location={0} />} />
    </Routes>
  );
};

export default AppRoutes;
