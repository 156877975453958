import React, { useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { acceptToken } from '../../actions';
import { Loading } from '../common';
import { AuthAdminState, StoreState } from '../../types';

const AcceptToken: React.FC = () => {
  const { authenticated, errors, loading } = useSelector<
    StoreState,
    AuthAdminState
  >((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = window.location.search.split('&');
    const token = query[0].replace('?token=', '');
    const uid = query[1].replace('uid=', '');
    dispatch(acceptToken(token, uid));
  }, [dispatch]);

  if (authenticated) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <Loading />;
  }
  // TODO show here any passwordless errors
  return (
    <Alert variant="danger">
      <strong>There was an error!</strong> {errors}
    </Alert>
  );
};

export default AcceptToken;
