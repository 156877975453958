import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logActions } from '../../actions';
import { ListActions, ListSearch } from '../common';
import LogList from './List';
import { LogAdminState, StoreState } from '../../types';

const Index: React.FC = () => {
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);

  const {
    all: items,
    count,
    fetching,
    requestError,
  } = useSelector<StoreState, LogAdminState>((state) => state.log);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAll = () => {
      dispatch(
        logActions.fetchAll(page, {
          search,
        }),
      );
    };
    fetchAll();
  }, [search, page, dispatch]);

  return (
    <div className="logs-index">
      <ListActions count={count}>
        <ListSearch
          placeholder="Search Logs"
          onChange={(search) => setSearch(search)}
          value={search}
        />
      </ListActions>
      <LogList
        fetching={fetching}
        items={items}
        error={requestError}
        count={count}
        loadMore={() => setPage(page + 1)}
      />
    </div>
  );
};

export default Index;
