const linkRegex = /^https?:\/\//;

export const required = {
  required: {
    value: true,
    message: 'Campo obrigatório.',
  },
};

export const validLink = {
  pattern: {
    value: linkRegex,
    message: 'Formato inválido para link HTTP/HTTPS.',
  },
};

export const requiredValidLink = {
  required: 'Campo obrigatório.',
  pattern: {
    value: linkRegex,
    message: 'Formato inválido para link HTTP/HTTPS.',
  },
};

export const isValidLink = (value: string) => linkRegex.test(value);

// export const document: (value, min, max) => {
//     let onlyNumbers = value.replace(/\D/g, "");
//
//     return (onlyNumbers.length === 14 || onlyNumbers.length === 11) ? undefined : 'Formato inválido!';
//   },
// };
