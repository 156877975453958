import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import UploadForm from './UploadForm';

interface UploadModalProps {
  show: boolean;
  handleHideModal: () => void;
  handleOnUpload: (file: any) => void;
}

const UploadModal: React.FC<UploadModalProps> = (props) => {
  const hideModal = () => {
    props.handleHideModal();
  };

  return (
    <Modal
      show={props.show}
      onHide={hideModal}
      dialogClassName="custom-modal"
      className="modal"
    >
      <UploadForm handleOnUpload={props.handleOnUpload} />
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadModal;
