import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required, requiredValidLink } from '../../helpers/validations';
import {
  TextInput,
  FileInput,
  CompanySelect,
  PlatformCheckboxes,
} from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { IntroductionAdmin } from '../../types';

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<IntroductionAdmin>({
    defaultValues: props.initialValues,
  });
  const { control, setValue } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="text"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Texto"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="details"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Detalhes"
            input={field}
            meta={fieldState}
          />
        )}
      />
      <Controller
        name="background"
        control={control}
        render={({ field, fieldState }) => (
          <FileInput
            //type="text"
            label="Imagem Desktop"
            input={field}
            meta={fieldState}
            setValue={setValue}
            className="required"
          />
        )}
        rules={requiredValidLink}
      />
      <Controller
        name="backgroundMobile"
        // type="text"
        control={control}
        render={({ field, fieldState }) => (
          <FileInput
            label="Imagem Mobile"
            input={field}
            meta={fieldState}
            setValue={setValue}
            className="required"
          />
        )}
        rules={requiredValidLink}
      />
      <Controller
        name="order"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label="Ordem"
            type="number"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="company"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            label="Cliente Corporativo"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />

      <PlatformCheckboxes control={control} />
    </GenericForm>
  );
};

export default Form;
