import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Alert, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye, faRemove } from '@fortawesome/free-solid-svg-icons';
import Imgix from 'react-imgix';
import { EmptyValues } from '../common';
import { parseUrl } from '../../helpers';
import moment from 'moment';
import { ListTableLogs } from './ListTableLogs';
import { useDispatch } from 'react-redux';
import { AppContext } from '../../contexts/AppContext';
import { ItemToShow, ItemType, Record } from '../../types';

interface ListTableProps {
  itemsToShow: ItemToShow[];
  items: Record[];
  modelUrl: string;
  lastId: string;
  canDelete: Function;
  onDelete: Function;
  onRestore: Function;
  readonly?: boolean;
  requestError: string;
  subItemRouteName?: string;
  isSubitems?: boolean;
  parentId?: string;
}

export const ListTable: React.FC<ListTableProps> = (props) => {
  const { showDeleted, setShowDeleted } = useContext(AppContext);

  const [modalDocumentId, setModalDocumentId] = useState<string | null>(null);

  const dispatch = useDispatch();

  const onDelete = (id: string) => {
    if (props.onDelete) {
      dispatch(props.onDelete(id));
    }
  };

  const onRestore = (id: string) => {
    if (props.onRestore) {
      dispatch(props.onRestore(id));
    }
  };

  const closeModal = () => {
    setModalDocumentId(null);
  };

  const openModal = (documentId: string) => {
    setModalDocumentId(documentId);
  };

  const createTableHeader = () => {
    return props.itemsToShow.map((itemToShow) => {
      return (
        <th
          key={itemToShow.name as string}
          className={itemToShow.type === 'image' ? 'small' : ''}
        >
          {itemToShow.title}
        </th>
      );
    });
  };

  const createDeleteOrRestButton = (item: Record) => {
    const disabled = props.canDelete && !props.canDelete(item);
    if (item.deleted) {
      return (
        <td key="Delete">
          <Button
            variant="outline-secondary"
            onClick={onRestore.bind(this, item._id)}
            disabled={disabled}
          >
            Rest
          </Button>
        </td>
      );
    } else {
      return (
        <td key="Delete">
          <Button
            variant="outline-secondary"
            onClick={onDelete.bind(this, item._id)}
            disabled={disabled}
          >
            <FontAwesomeIcon icon={faRemove} />
          </Button>
        </td>
      );
    }
  };

  const createEditDelete = (item: Record) => {
    //const { show, order } = item;
    if (!props.readonly) {
      return [
        <td key="Edit">
          <Link to={generateUrl('edit', item)} className="btn btn-outline-dark">
            <FontAwesomeIcon icon={faEdit} />
          </Link>
        </td>,
        createDeleteOrRestButton(item),
        // (
        //   <td key='Mostrar'>
        //     {show ? 'Sim' : 'Não' }
        //   </td>
        // ),
        // (
        //   <td key='Ordem'>
        //     {order}
        //   </td>
        // )
      ];
    }
    return (
      <td style={{ width: '5%' }}>
        <Link to={generateUrl('show', item)} className="btn btn-light">
          <FontAwesomeIcon icon={faEye} size="2x" />
        </Link>
      </td>
    );
  };

  const displayHeadersWrite = () => {
    if (!props.readonly) {
      return [
        <th className="title action" key={0}>
          Alterar
        </th>,
        <th className="title action" key={1}>
          Excluir
        </th>,
        // (
        //   <th className='title' key={2}>Mostrar</th>
        // ),
        // (
        //   <th className='title' key={3}>Ordem</th>
        // )
      ];
    }
    return <th className="title">Visualizar</th>;
  };

  const generateUrl = (type: string, { _id }: Record) => {
    let subItemRouteName = props.subItemRouteName || 'subitems';
    return props.isSubitems
      ? `/${props.modelUrl}/${props.parentId}/${subItemRouteName}/${_id}/${type}`
      : `/${props.modelUrl}/${_id}/${type}`;
  };

  const createTd = (item: Record, itemToShow: ItemToShow, key: React.Key) => {
    const { type, name, nowrap, render } = itemToShow;
    const [property, subproperty] = Array.isArray(name)
      ? name
      : [name, undefined];
    let value = item[property as keyof Record] as any;

    if (render) {
      value = render(value);
    }
    if (!value && type !== ItemType.boolean && type !== ItemType.number) {
      value = '-';
    } else {
      switch (type) {
        case ItemType.image:
          value = (
            <a
              className="img-wrapper"
              href={parseUrl(value)}
              target="_blank"
              rel="noreferrer"
            >
              <Imgix
                src={parseUrl(value)}
                className="img-responsive"
                width={60}
                height={40}
                imgixParams={{
                  fit: 'false',
                }}
              />
            </a>
          );
          break;
        case ItemType.array: // name: ['brands', 'name'], type: 'array'
          value = (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {value.map((elem: any, index: number) => (
                <li key={index}>{subproperty ? elem[subproperty] : elem}</li>
              ))}
            </ul>
          );
          break;
        case ItemType.object: // name: ['our_make', 'name'], type: ItemType.object
          value = value[subproperty as keyof Record];
          break;
        case ItemType.date:
          value = moment(value).format('DD/MM/YYYY HH:mm');
          break;
        case ItemType.boolean:
          value = value ? 'Sim' : 'Não';
          break;
        default:
        // no action needed
      }
    }
    return (
      <td
        key={key}
        className={type === 'image' ? 'image-thumb' : ''}
        style={nowrap ? { whiteSpace: 'nowrap' } : {}}
      >
        {value}
      </td>
    );
  };

  const getRowClasses = (item: Record, isLastUpdated: boolean) => {
    let className = '';

    if (item.deleted) {
      className += ' table-danger';
    }
    if (isLastUpdated) {
      className += ' table-success';
    }
    // if ((item.expiresAt !== null && moment(item.expiresAt) < moment()) || moment(item.publishedAt) > moment()) {
    //   className += ' text-danger';
    // }
    return className.trim();
  };

  const createTableBody = () => {
    return props.items.map((item) => {
      // if (this.state.showDeleted === false && item.deleted) {
      //   return null;
      // }

      let tds = props.itemsToShow.map((itemToShow, key) => {
        return createTd(item, itemToShow, key);
      });

      const isLastUpdated = item._id === props.lastId;

      return (
        <tr key={item._id} className={getRowClasses(item, isLastUpdated)}>
          {createEditDelete(item)}
          {tds}
          {/*<td>{moment(item.publishedAt || item.createdAt).format('DD/MM/YYYY HH:mm')}</td>*/}
          {/*<td>{item.expiresAt ? moment(item.expiresAt).format('DD/MM/YYYY HH:mm') : 'Não expira'}</td>*/}
          <td
            onClick={() => openModal(item._id)}
            className="text-primary"
            style={{ cursor: 'pointer' }}
          >
            {item.lastUser && item.lastUser.split('@')[0]}
          </td>
        </tr>
      );
    });
  };

  const renderForm = () => {
    return props.readonly ? null : (
      <div className="checkbox d-flex flex-column align-items-end col-md-12">
        <Form.Check
          name="showDeleted"
          type="checkbox"
          checked={showDeleted}
          onChange={(e) => setShowDeleted(e.target.checked)}
          label="Exibir deletados"
        />
      </div>
    );
  };

  if (props.requestError) {
    return (
      <div>
        <br />
        <Alert variant="danger">
          <strong>Oops!</strong> {props.requestError}
        </Alert>
      </div>
    );
  }

  return (
    <div>
      {modalDocumentId && (
        <ListTableLogs documentId={modalDocumentId} closeModal={closeModal} />
      )}
      <div className="row">{renderForm()}</div>
      {!props.items.length ? (
        <EmptyValues />
      ) : (
        <Table responsive hover>
          <thead>
            <tr>
              {displayHeadersWrite()}
              {createTableHeader()}
              {/*<th className='medium'>Publicação</th>*/}
              {/*<th className='medium'>Expiração</th>*/}
              <th className="small" colSpan={2}>
                Log
              </th>
            </tr>
          </thead>
          <tbody>{createTableBody()}</tbody>
        </Table>
      )}
    </div>
  );
};
