import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../helpers/validations';
import {
  TextInput,
  CompanySelect,
  SelectInput,
  TextareaInput,
} from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { ServiceChannel } from 'cdfdigital-common/src/models';

const channelTypes = [
  'WhatsApp',
  'Chat',
  'CallMe',
  'VideoCall',
  'Call',
  'Email',
];

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<ServiceChannel>({
    defaultValues: props.initialValues,
  });
  const { control } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="type"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            input={field}
            meta={fieldState}
            label="Tipo"
            className="required"
            options={channelTypes.map((type) => ({ value: type, label: type }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="contact"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            input={field}
            meta={fieldState}
            label="Contato"
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="disclaimer"
        control={control}
        render={({ field, fieldState }) => (
          <TextareaInput
            type="text"
            input={field}
            meta={fieldState}
            label="Disclaimer"
          />
        )}
      />
      <Controller
        name="order"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="number"
            input={field}
            meta={fieldState}
            label="Ordem"
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="company"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            label="Cliente Corporativo"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
    </GenericForm>
  );
};

export default Form;
