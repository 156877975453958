import React from 'react';
import { useDispatch } from 'react-redux';
import { signinGoogle } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleSuccess = (response: any) => {
    dispatch(signinGoogle(response));
  };

  const handleFailure = () => {
    // handle Google sign-in failure here
  };

  const signIn = useGoogleLogin({
    onSuccess: handleSuccess,
    flow: 'auth-code',
    onError: handleFailure,
  });

  return (
    <button onClick={() => signIn()} className="btn btn-danger">
      <FontAwesomeIcon icon={faGoogle} size="lg" className="me-2" />
      Acessar com sua Conta Google
    </button>
  );
};

export default GoogleButton;
