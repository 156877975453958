import { TabAdmin } from '../types';
import { commonProps } from './commonProps';

export const tab: Omit<TabAdmin, '_id'> = {
  ...commonProps,
  title: '',
  details: '',
  iconName: '',
  iconType: '',
  route: '',
  order: 0,
};
