import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { signinPasswordless } from '../../actions';
import { CheckBoxInput, TextInput } from '../common';
import { AuthAdminState, StoreState } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

interface FormData {
  email: string;
  remind: boolean;
}

const PasswordlessForm: React.FC = () => {
  const { passwordless } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const dispatch = useDispatch();

  const displayAlert = () => {
    if (passwordless.active || passwordless.errors) {
      return (
        <Alert variant="info">
          Se o usuário informado existir no nosso banco, você receberá um email
          para fazer o login.
        </Alert>
      );
    }
  };

  const renderSendingIcon = () => {
    if (passwordless.sending) {
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin={true}
          size="lg"
          className="me-2"
          aria-hidden="true"
        />
      );
    }
    return (
      <FontAwesomeIcon
        icon={faPaperPlane}
        size="lg"
        className="me-2"
        aria-hidden="true"
      />
    );
  };

  const signin = ({ email, remind }: FormData) => {
    localStorage.setItem('email', remind ? email : '');
    dispatch(signinPasswordless(email));
  };

  const { handleSubmit, watch, control } = useForm<FormData>({
    defaultValues: {
      email: localStorage.getItem('email') || '',
      remind: true,
    },
  });

  return (
    <form onSubmit={handleSubmit(signin)}>
      {displayAlert()}
      <Row>
        <Col md={7}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextInput
                type="email"
                placeholder="seu email"
                input={field}
                meta={fieldState}
              />
            )}
          />
        </Col>
        <Col md={5}>
          <div className="buttons-separation">
            <Button
              variant="primary"
              type="submit"
              disabled={passwordless.sending || !watch().email}
            >
              {renderSendingIcon()} Enviar Link de Acesso
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Controller
            name="remind"
            control={control}
            render={({ field, fieldState }) => (
              <CheckBoxInput
                input={field}
                meta={fieldState}
                label="Lembrar email para acesso futuro"
              />
            )}
          />
        </Col>
      </Row>
    </form>
  );
};

export default PasswordlessForm;
