import React from 'react';
import { luckyNumberActions } from '../../actions';
import { GenericListConfig, ItemType } from '../../types';
import { GenericRoutes } from '../_generic_crud';
import { luckyNumber } from '../../defaultValues';
import Form from './Form';

const config: GenericListConfig = {
  path: 'lucky_number',
  stateKey: 'luckyNumber',
  newButtonLabel: 'Nova Descrição Número da Sorte',
  headerLabel: 'Editar Descrição Número da Sorte',
  itemsToShow: [
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const LuckNumberRoutes: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={luckyNumberActions}
      defaultValues={luckyNumber}
      Form={Form}
    />
  );
};

export default LuckNumberRoutes;
