import React from 'react';
import { newsActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { GenericListConfig, ItemType } from '../../types';
import Form from './Form';
import { newsItem } from '../../defaultValues';

const config: GenericListConfig = {
  path: 'news',
  stateKey: 'news',
  newButtonLabel: 'Nova Notícia',
  headerLabel: 'Editar Notícia',
  itemsToShow: [
    { name: 'title', title: 'Título', type: ItemType.text },
    { name: 'link', title: 'Link', type: ItemType.text },
    { name: 'image', title: 'Imagem', type: ItemType.image },
    { name: 'publishedAt', title: 'Publicação', type: ItemType.date },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={newsActions}
      defaultValues={newsItem}
      Form={Form}
    />
  );
};

export default Index;
