import React from 'react';
import { serviceChannelActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { GenericListConfig, ItemType } from '../../types';
import { serviceChannel } from '../../defaultValues';
import Form from './Form';

const config: GenericListConfig = {
  path: 'service_channels',
  stateKey: 'serviceChannel',
  newButtonLabel: 'Novo Canal de Atendimento',
  headerLabel: 'Editar Canal de Atendimento',
  itemsToShow: [
    { name: 'order', title: 'Ordem', type: ItemType.number },
    { name: 'type', title: 'Tipo', type: ItemType.text },
    { name: 'contact', title: 'Contato', type: ItemType.text },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={serviceChannelActions}
      defaultValues={serviceChannel}
      Form={Form}
    />
  );
};

export default Index;
