import React from 'react';
import { FormGroup, Form, FormControl } from 'react-bootstrap';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ErrorOrHelp } from './ErrorOrHelp';

interface TextInputProps {
  input: ControllerRenderProps<any, any>;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  type: any;
  meta: ControllerFieldState;
  help?: string;
  className?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  input,
  label,
  placeholder,
  disabled,
  type,
  className,
  meta: { error },
  help,
}) => {
  if (type === 'file' && input.value) {
    input.value = undefined;
  }
  return (
    <FormGroup
      controlId={input.name}
      className={['form-group', className].join(' ')}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <FormControl
        {...input}
        placeholder={placeholder || label}
        type={type}
        disabled={disabled}
        className={error ? 'is-invalid' : ''}
      />
      <ErrorOrHelp error={error} help={help} />
    </FormGroup>
  );
};
