import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../helpers/validations';
import { TextInput } from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { CompanyAdmin } from '../../types';

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<CompanyAdmin>({
    defaultValues: props.initialValues,
  });
  const { control } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="externalId"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label="ID Externo"
            type="number"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Nome"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="smsSignature"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="SMS Signature"
            input={field}
            meta={fieldState}
            className="required"
            help="Necessário no Android para auto-preenchimento do código SMS via react-native-otp-verify"
          />
        )}
        rules={required}
      />
      <Controller
        name="takiUsername"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Taki Username"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="takiPassword"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Taki Password"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
    </GenericForm>
  );
};

export default Form;
