import {
  faCog,
  faMobileScreen,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

export const sections = [
  {
    name: 'Aplicativo',
    icon: faMobileScreen,
    links: [
      {
        path: '/introductions',
        label: 'Introdução',
      },
      {
        path: '/home_links',
        label: 'Home',
      },
      {
        path: '/tabs',
        label: 'Abas',
      },
      {
        path: '/news',
        label: 'Notícias',
      },
      {
        path: '/media',
        label: 'Mídia',
      },
      {
        path: '/service_channels',
        label: 'Canais de Atendimento',
      },
      {
        path: '/tools',
        label: 'Ferramentas',
      },
      {
        path: '/terms_of_service',
        label: 'Termos de Serviço',
      },
      {
        path: '/lucky_number',
        label: 'Número da Sorte',
      },
    ],
  },
  {
    name: 'Admin',
    role: 'admin',
    icon: faCog,
    links: [
      {
        path: '/backend_users',
        label: 'Usuários',
      },
    ],
  },
  {
    name: 'Super Admin',
    role: 'sa',
    icon: faWrench,
    links: [
      {
        path: '/companies',
        label: 'Clientes Corporativos',
      },
      {
        path: '/logs',
        label: 'Logs',
      },
    ],
  },
];
