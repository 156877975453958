import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../helpers/validations';
import { TextareaInput, CompanySelect } from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { LuckyNumberAdmin } from '../../types';

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<LuckyNumberAdmin>({
    defaultValues: props.initialValues,
  });
  const { control } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <TextareaInput
            type="text"
            label="Descrição"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="regulation"
        control={control}
        render={({ field, fieldState }) => (
          <TextareaInput
            type="text"
            label="Regulamento"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="company"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            label="Cliente Corporativo"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
    </GenericForm>
  );
};

export default Form;
