import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../helpers/validations';
import { TextInput, SelectInput, CompanySelect } from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { TabAdmin } from '../../types';

const routes = [
  'News',
  'ServiceChannels',
  'Schedule',
  'Tools',
  'StoreLocation',
  'Account',
  'Media',
];

const iconTypes = ['feather', 'entypo', 'font-awesome'];

const icons = [
  'file-text',
  'headphones',
  'calendar-check-o',
  'cog',
  'map-pin',
  'user',
  'heart',
  'activity',
  'clipboard',
];

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<TabAdmin>({
    defaultValues: props.initialValues,
  });
  const { control } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            input={field}
            meta={fieldState}
            type="text"
            label="Título"
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="route"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            input={field}
            meta={fieldState}
            label="Rota"
            className="required"
            options={routes.map((type) => ({ value: type, label: type }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="iconType"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            input={field}
            meta={fieldState}
            label="Tipo de Ícone"
            className="required"
            options={iconTypes.map((type) => ({ value: type, label: type }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="iconName"
        control={control}
        render={({ field, fieldState }) => (
          <SelectInput
            input={field}
            meta={fieldState}
            label="Ícone"
            className="required"
            options={icons.map((type) => ({ value: type, label: type }))}
          />
        )}
        rules={required}
      />
      <Controller
        name="order"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="number"
            input={field}
            meta={fieldState}
            label="Ordem"
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="company"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            input={field}
            meta={fieldState}
            label="Cliente Corporativo"
            //className="required"
          />
        )}
        rules={required}
      />
    </GenericForm>
  );
};

export default Form;
