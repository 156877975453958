import React from 'react';
import { Row, Col, Card, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import GoogleButton from './GoogleButton';
import { AuthAdminState, StoreState } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Auth: React.FC = () => {
  const { errors } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const displayAlert = () => {
    if (errors) {
      return (
        <Alert variant="danger">
          <strong>Oops!</strong> {errors}
        </Alert>
      );
    }
  };

  return (
    <div className="auth-frame">
      <Card>
        <Card.Header>InterAdmin | CDF Digital</Card.Header>
        <Card.Body>
          {displayAlert()}
          <Row>
            <Col className="flex-grow">
              <GoogleButton />
            </Col>
            <Col className="d-flex align-items-center">
              <div>ou</div>
            </Col>
            <Col className="flex-grow">
              <Link to="/auth/link" className="btn btn-primary">
                <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-2" />
                Enviar Link de Acesso por Email
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Auth;
