import { HomeLinkAdmin } from '../types';
import { commonProps } from './commonProps';

export const homeLink: Omit<HomeLinkAdmin, '_id'> = {
  ...commonProps,
  title: '',
  subtitle: '',
  image: '',
  imageDesktop: '',
  description: '',
  route: '',
  routeSlug: '',
  textPosition: '',
  order: 0,
  platforms: {
    ios: false,
    android: false,
    web: false,
  },
};
