import { createContext, useEffect, useState } from 'react';

type AppContextType = {
  showDeleted: boolean;
  setShowDeleted: (value: boolean) => void;
};

export const AppContext = createContext<AppContextType>({
  showDeleted: false,
  setShowDeleted: (value) => console.warn('no provider'),
});

type AppContextProviderProps = {
  children: React.ReactNode;
};

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}) => {
  const [showDeleted, setShowDeleted] = useState(
    localStorage.getItem('showDeleted') === 'true',
  );

  useEffect(() => {
    localStorage.setItem('showDeleted', showDeleted ? 'true' : 'false');
  }, [showDeleted]);

  return (
    <AppContext.Provider value={{ showDeleted, setShowDeleted }}>
      {children}
    </AppContext.Provider>
  );
};
