import React from 'react';
import { tabActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { GenericListConfig, ItemType } from '../../types';
import Form from './Form';
import { tab } from '../../defaultValues';

const config: GenericListConfig = {
  path: 'tabs',
  stateKey: 'tab',
  newButtonLabel: 'Nova Aba',
  headerLabel: 'Editar Aba',
  itemsToShow: [
    { name: 'order', title: 'Ordem', type: ItemType.number },
    { name: 'title', title: 'Título', type: ItemType.text },
    { name: 'route', title: 'Rota', type: ItemType.text },
    { name: 'iconType', title: 'Tipo de Ícone', type: ItemType.text },
    { name: 'iconName', title: 'Ícone', type: ItemType.text },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={tabActions}
      defaultValues={tab}
      Form={Form}
    />
  );
};

export default Index;
