import { BackendUserAdmin } from '../types/AdminState';
import { commonProps } from './commonProps';

export const backendUser: Omit<BackendUserAdmin, '_id'> = {
  ...commonProps,
  name: '',
  email: '',
  phone: '',
  picture: '',
  role: 'user',
  companies: '',
};
