import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthAdminState, StoreState } from '../types';

interface RequireGuestProps {
  children: React.ReactNode;
}

const RequireGuest: React.FC<RequireGuestProps> = ({ children }) => {
  const { authenticated } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  if (authenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default RequireGuest;
