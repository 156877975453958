import React from 'react';
import 'moment/locale/pt-br';
import { DateTimePicker, Localization } from 'react-widgets';
import { DateLocalizer } from 'react-widgets/IntlLocalizer';
import { FormGroup, Form } from 'react-bootstrap';
import 'react-widgets/styles.css';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ErrorOrHelp } from './ErrorOrHelp';

interface DateInputProps {
  input: ControllerRenderProps<any, any>;
  label: string;
  placeholder?: string;
  type?: string;
  time?: boolean;
  min?: Date;
  meta: ControllerFieldState;
  help?: string;
}

const locale = {
  label: 'Brazilian Portuguese',
  culture: 'pt-BR',
};

export const DateInput: React.FC<DateInputProps> = ({
  input,
  label,
  // placeholder,
  // type,
  time = true,
  min,
  meta: { error },
  help,
}) => (
  <FormGroup controlId={input.name} className="form-group col-6">
    <Form.Label>{label}</Form.Label>
    <Localization date={new DateLocalizer(locale)}>
      <DateTimePicker
        onChange={input.onChange}
        value={new Date(input.value)}
        min={min}
        includeTime
        step={30}
      />
    </Localization>
    <ErrorOrHelp error={error} help={help} />
  </FormGroup>
);
