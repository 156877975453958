import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required, requiredValidLink } from '../../helpers/validations';
import {
  TextInput,
  DateInput,
  FileInput,
  CompanySelect,
  TextareaInput,
} from '../common';
import GenericForm, { GenericFormProps } from '../_generic_crud/GenericForm';
import { MediaItemAdmin } from '../../types';

const Form: React.FC<GenericFormProps> = (props) => {
  const form = useForm<MediaItemAdmin>({
    defaultValues: props.initialValues,
  });
  const { control, setValue } = form;

  return (
    <GenericForm {...props} form={form}>
      <Controller
        name="title"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Título"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Descrição"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="text"
        control={control}
        render={({ field, fieldState }) => (
          <TextareaInput
            type="text"
            label="Texto"
            input={field}
            meta={fieldState}
          />
        )}
      />
      <Controller
        name="tags"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput type="text" label="Tags" input={field} meta={fieldState} />
        )}
      />
      <Controller
        name="image"
        control={control}
        render={({ field, fieldState }) => (
          <FileInput
            //type="text"
            label="Imagem"
            input={field}
            meta={fieldState}
            setValue={setValue}
            className="required"
          />
        )}
        rules={requiredValidLink}
      />
      <Controller
        name="video"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            type="text"
            label="Video"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={requiredValidLink}
      />
      <Controller
        name="publishedAt"
        control={control}
        render={({ field, fieldState }) => (
          <DateInput
            label="Data de Publicação"
            input={field}
            meta={fieldState}
          />
        )}
      />
      <Controller
        name="company"
        control={control}
        render={({ field, fieldState }) => (
          <CompanySelect
            label="Cliente Corporativo"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
      <Controller
        name="order"
        control={control}
        render={({ field, fieldState }) => (
          <TextInput
            label="Ordem"
            type="number"
            input={field}
            meta={fieldState}
            className="required"
          />
        )}
        rules={required}
      />
    </GenericForm>
  );
};

export default Form;
