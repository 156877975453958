import React, { useContext, useEffect, useState } from 'react';
import { ListActions, ListSearch, ListTable, InfiniteScroll } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import {
  Actions,
  AuthAdminState,
  CompanyAdminState,
  GenericListConfig,
  RecordAdminState,
  StoreState,
} from '../../types';
import { AppContext } from '../../contexts/AppContext';

interface GenericListProps {
  config: GenericListConfig;
  canDelete?: any;
  //resetLastId: any;
  //location: any;
  actions: Actions;
}

const GenericList: React.FC<GenericListProps> = ({
  config: { path, stateKey, newButtonLabel, itemsToShow },
  canDelete,
  actions,
}) => {
  const { showDeleted } = useContext(AppContext);

  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);

  const {
    fetching,
    all: items,
    count,
    lastId,
    success,
    requestError,
  } = useSelector<StoreState, RecordAdminState<any>>(
    (state) => state[stateKey],
  );

  const { currentCompany } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const { all: companies } = useSelector<StoreState, CompanyAdminState>(
    (state) => state.company,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAll = () => {
      dispatch(
        actions.fetchAll(
          page,
          {
            search,
          },
          showDeleted,
        ),
      );
    };
    fetchAll();
  }, [search, page, actions, dispatch, showDeleted, currentCompany, success]);

  // useEffect(() => {
  //   // const init = async () => {
  //   //   const { location } = props;
  //   //   if (location.state && location.state.fromSidebar) {
  //   //     await props.actions.setSearch(undefined);
  //   //   }
  //   //   dispatch(fetchItems());
  //   // };
  //   // init();
  //   //this.props.resetLastId();
  //   fetchItems();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className={`${path}-index`}>
      <ListActions count={count}>
        <Link to="new" className="btn btn-success">
          <FontAwesomeIcon icon={faAdd} style={{ marginRight: 5 }} />
          {newButtonLabel}
        </Link>
        <ListSearch
          placeholder="Procurar"
          onChange={(search) => setSearch(search)}
          value={search}
        />
      </ListActions>

      <InfiniteScroll
        fetching={fetching}
        length={items.length}
        count={count}
        loadMore={() => setPage(page + 1)}
      >
        <ListTable
          items={items}
          requestError={requestError}
          itemsToShow={itemsToShow.filter(
            (x: any) =>
              companies.length > 1 ||
              !['company', 'companies'].includes(x.name[0]),
          )}
          modelUrl={path}
          canDelete={canDelete}
          onDelete={actions.delete}
          onRestore={actions.restore}
          lastId={lastId}
        />
      </InfiniteScroll>
    </div>
  );
};

export default GenericList;
