import { ToolAdmin } from '../types';
import { commonProps } from './commonProps';

export const tool: Omit<ToolAdmin, '_id'> = {
  ...commonProps,
  name: '',
  description: '',
  image: '',
  id_slug: '',
  markdown: '',
  order: 0,
};
