import React from 'react';

interface NoMatchProps {
  location: any;
}

const NoMatch: React.FC<NoMatchProps> = ({ location }) => (
  <div className="404">
    <div className="jumbotron">
      <p className="text-center">Sorry, Page not found.</p>
    </div>
  </div>
);

export { NoMatch };
