export const COMPANY = 'COMPANY';
export const LOG = 'LOG';
export const BACKEND_USER = 'BACKEND_USER';
export const INTRODUCTION = 'INTRODUCTION';
export const NEWS = 'NEWS';
export const MEDIA = 'MEDIA';
export const SERVICE_CHANNEL = 'SERVICE_CHANNEL';
export const TOOL = 'TOOL';
export const TAB = 'TAB';
export const HOME_LINK = 'HOME_LINK';
export const TERMS_OF_SERVICE = 'TERMS_OF_SERVICE';
export const LUCKY_NUMBER = 'LUCKY_NUMBER';
