import React from 'react';
import { useSelector } from 'react-redux';
import {
  Actions,
  GenericListConfig,
  RecordAdminState,
  StoreState,
} from '../../types';

interface GenericNewProps {
  config: GenericListConfig;
  actions: Actions;
  Form: React.FC<any>;
  defaultValues: any;
}

export const GenericNew: React.FC<GenericNewProps> = ({
  config: { path, stateKey, newButtonLabel },
  actions,
  Form,
  defaultValues,
}) => {
  const { fetching, success, requestError } = useSelector<
    StoreState,
    RecordAdminState<any>
  >((state) => state[stateKey]);

  return (
    <div className={`${path}-new`}>
      <h3>{newButtonLabel}</h3>
      <Form
        initialValues={defaultValues}
        fetching={fetching}
        success={success}
        requestError={requestError}
        onSave={actions.create}
        path={path}
      />
    </div>
  );
};
