import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PasswordlessForm from './PasswordlessForm';

const AuthLink: React.FC = () => {
  return (
    <div className="auth-frame">
      <Card>
        <Card.Header>
          <Link to="/">InterAdmin | CDF Digital</Link>
        </Card.Header>
        <Card.Body>
          <PasswordlessForm />
        </Card.Body>
      </Card>
    </div>
  );
};

export default AuthLink;
