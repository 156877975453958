import {
  FETCH_ALL,
  FETCH_ONE,
  ERROR,
  CREATE,
  UPDATE,
  DELETE,
  RESTORE,
  REQUEST_LOADING,
  REQUEST_NOT_LOADING,
  SET_SEARCH,
  RESET_LAST_ID,
  CLEAR_STATUS,
} from '../actions/generic_rest';

export default function genericRest(prefix: string) {
  // , initialState = {}
  const INITIAL_STATE = {
    all: [],
    record: null,
    count: 0,
    // skip: 0,
    // limit: 20,
    page: 0,
    fetching: false,
    requestError: null,
    search: undefined,
    lastId: null,
    success: false,
    //, ...initialState
  };
  //  Generic reducer for common REST actions
  return function (state = INITIAL_STATE, action: any) {
    switch (action.type) {
      case prefix + '/' + FETCH_ALL: {
        return {
          ...state,
          all:
            action.payload.page === 0
              ? action.payload.data
              : state.all.concat(action.payload.data),
          page: action.payload.page,
          // skip: Number(action.payload.skip),
          // limit: Number(action.payload.limit),
          count: Number(action.payload.count),
          fetching: false,
          requestError: null,
        };
      }
      case prefix + '/' + FETCH_ONE:
        return {
          ...state,
          record: action.payload,
          fetching: false,
          requestError: null,
        };
      case prefix + '/' + ERROR:
        return {
          ...state,
          fetching: false,
          requestError: action.payload,
        };
      case prefix + '/' + CREATE:
      case prefix + '/' + UPDATE:
      case prefix + '/' + DELETE:
      case prefix + '/' + RESTORE:
        return {
          ...state,
          lastId: action.payload,
          success: true,
        };
      case prefix + '/' + REQUEST_LOADING:
        return {
          ...state,
          fetching: true,
        };
      case prefix + '/' + REQUEST_NOT_LOADING:
        return {
          ...state,
          fetching: false,
        };
      case prefix + '/' + CLEAR_STATUS:
        return {
          ...state,
          fetching: false,
          success: false,
        };
      case prefix + '/' + SET_SEARCH:
        return {
          ...state,
          search: action.payload,
        };
      case prefix + '/' + RESET_LAST_ID:
        return {
          ...state,
          lastId: INITIAL_STATE.lastId,
        };
      default:
        return state;
    }
  };
}
