import genericRestActions from './generic_rest';

// GENERIC REST ACTIONS:
import {
  COMPANY,
  BACKEND_USER,
  LOG,
  INTRODUCTION,
  NEWS,
  MEDIA,
  SERVICE_CHANNEL,
  TOOL,
  TAB,
  HOME_LINK,
  TERMS_OF_SERVICE,
  LUCKY_NUMBER,
} from './types';

export * from './upload';
export * from './auth';

export const companyActions = genericRestActions(COMPANY, '/companies', {
  filterCompany: false,
});
export const logActions = genericRestActions(LOG, '/logs');
export const backendUserActions = genericRestActions(
  BACKEND_USER,
  '/backend_users'
);
export const introductionActions = genericRestActions(
  INTRODUCTION,
  '/introductions'
);
export const newsActions = genericRestActions(NEWS, '/news');
export const mediaActions = genericRestActions(MEDIA, '/media');
export const serviceChannelActions = genericRestActions(
  SERVICE_CHANNEL,
  '/service_channels'
);
export const toolActions = genericRestActions(TOOL, '/tools');
export const tabActions = genericRestActions(TAB, '/tabs');
export const homeLinkActions = genericRestActions(HOME_LINK, '/home_links');
export const termsOfServiceActions = genericRestActions(
  TERMS_OF_SERVICE,
  '/terms_of_service'
);
export const luckyNumberActions = genericRestActions(
  LUCKY_NUMBER,
  '/lucky_number'
);
