import React, { useEffect } from 'react';
// import { TextInput } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage, clearUploadImage } from '../../actions';
import { Button, Alert } from 'react-bootstrap';
import { Loading } from '../common';
//import { required } from '../../helpers/validations';
import { StoreState, UploadAdminState } from '../../types';

interface UploadFormProps {
  handleOnUpload: Function;
}

const UploadForm: React.FC<UploadFormProps> = (props) => {
  const { url, uploadError, fetching } = useSelector<
    StoreState,
    UploadAdminState
  >((state) => state.upload);

  const [file, setFile] = React.useState<File>();

  const dispatch = useDispatch();

  const handleUpload = () => {
    dispatch(uploadImage([file]));
  };

  useEffect(() => {
    if (url) {
      props.handleOnUpload(url);
      dispatch(clearUploadImage());
    }
  }, [dispatch, url, props]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  if (fetching) {
    return <Loading />;
  }
  return (
    <div>
      {uploadError && (
        <Alert variant="danger">Ocorreu um erro durante o upload</Alert>
      )}
      <label>Select File</label>
      <input type="file" onChange={handleChange} />
      {file && (
        <Button variant="primary" onClick={handleUpload}>
          Upload Image
        </Button>
      )}
    </div>
  );
};

export default UploadForm;
