import { GenericRoutes } from '../_generic_crud';
import Form from './Form';
import { GenericListConfig, ItemType } from '../../types';
import { RenderPlatforms } from '../common';
import { homeLinkActions } from '../../actions';
import { homeLink } from '../../defaultValues';

const config: GenericListConfig = {
  path: 'home_links',
  stateKey: 'homeLink',
  newButtonLabel: 'Novo Link da Home',
  headerLabel: 'Editar Link da Home',
  itemsToShow: [
    { name: 'order', title: 'Ordem', type: ItemType.number },
    { name: 'platforms', title: 'Plataforma', render: RenderPlatforms },
    { name: 'textPosition', title: 'Posição', type: ItemType.text },
    { name: 'title', title: 'Título', type: ItemType.text },
    { name: 'route', title: 'Rota', type: ItemType.text },
    { name: 'image', title: 'Imagem', type: ItemType.image },
    { name: 'imageDesktop', title: 'Imagem Desktop', type: ItemType.image },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const HomeLinksRoutes: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={homeLinkActions}
      defaultValues={homeLink}
      Form={Form}
    />
  );
};

export default HomeLinksRoutes;
