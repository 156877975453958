import React from 'react';
import Select, { MultiValue } from 'react-select';
import { FormGroup, Form } from 'react-bootstrap';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ReactSelectOption as Option } from '../../../types';
import { ErrorOrHelp } from './ErrorOrHelp';

interface SelectMultipleInputProps {
  input: ControllerRenderProps<any, any>;
  label?: string;
  className?: string;
  meta: ControllerFieldState;
  options: Option[];
  help?: string;
}

export const SelectMultipleInput: React.FC<SelectMultipleInputProps> = ({
  input,
  label,
  className,
  meta: { error },
  options,
  help,
}) => {
  const onChange = (values: MultiValue<Option>) => {
    // Fix Select 2 submits objects instead of IDs
    input.onChange(values.map(({ value }) => value));
  };

  let value: Option[] = [];

  if (input.value) {
    value = options.filter((option) => input.value.includes(option.value));
  }

  return (
    <FormGroup className={['form-group', className].join(' ')}>
      <Form.Label>{label}</Form.Label>
      <Select
        name={input.name}
        value={value}
        isMulti
        options={options}
        onChange={onChange}
        onBlur={input.onBlur}
      />
      <ErrorOrHelp error={error} help={help} />
    </FormGroup>
  );
};
