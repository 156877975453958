import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import Header from './Header';
import Sidebar from './Sidebar';
import AppRoutes from '../../routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/app.scss';
import { AuthAdminState, StoreState } from '../../types';
import { AppContextProvider } from '../../contexts/AppContext';

const Index: React.FC = () => {
  const history = undefined; // FIXME

  const { authenticated /*, currentCompany*/ } = useSelector<
    StoreState,
    AuthAdminState
  >((state) => state.auth);

  const renderSidebar = () => {
    if (!authenticated) {
      return null;
    }
    return <Sidebar />;
  };

  const renderContent = () => {
    if (!authenticated) {
      return <AppRoutes history={history} />;
    }

    return (
      <>
        <Header />
        <Container fluid className="container-margin">
          <Row>
            {renderSidebar()}
            <Col md={authenticated ? 10 : 12} className="ms-sm-auto right-side">
              <AppRoutes history={history} />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <AppContextProvider>
      <Router>{renderContent()}</Router>
    </AppContextProvider>
  );
};

export default Index;
