export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_ERRORS = 'AUTH_ERRORS';
export const AUTH_CURRENTLY_LOGGED_IN = 'AUTH_CURRENTLY_LOGGED_IN';
export const AUTH_PASSWORDLESS = 'AUTH_PASSWORDLESS';
export const AUTH_PASSWORDLESS_ERRORS = 'AUTH_PASSWORDLESS_ERRORS';
export const AUTH_PASSWORDLESS_SENDING = 'AUTH_PASSWORDLESS_SENDING';
export const AUTH_PASSWORDLESS_LOADING = 'AUTH_PASSWORDLESS_LOADING';
export const AUTH_PASSWORDLESS_NOT_LOADING = 'AUTH_PASSWORDLESS_NOT_LOADING';
export const AUTH_SET_CURRENT_COMPANY = 'AUTH_SET_CURRENT_COMPANY';
