import { ServiceChannelAdmin } from '../types';
import { commonProps } from './commonProps';

export enum ChannelType {
  WhatsApp = 'WhatsApp',
  Chat = 'Chat',
  CallMe = 'CallMe',
  VideoCall = 'VideoCall',
  Call = 'Call',
  Email = 'Email',
}

export const serviceChannel: Omit<ServiceChannelAdmin, '_id'> = {
  ...commonProps,
  company: '',
  type: ChannelType.Email,
  disclaimer: '',
  contact: '',
  order: 0,
};
