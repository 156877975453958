import React from 'react';
import { useSelector } from 'react-redux';
import { backendUserActions } from '../../actions';
import { hasRole } from '../../helpers/roles';
import GenericList from '../_generic_crud/GenericList';
import {
  AuthAdminState,
  GenericListConfig,
  ItemType,
  StoreState,
} from '../../types';
import { Route, Routes } from 'react-router-dom';
import { backendUser } from '../../defaultValues';
import { GenericNew } from '../_generic_crud';
import Form from './Form';
import { Edit } from './Edit';
import Logs from './logs';

const BackendUsersRoutes: React.FC = () => {
  const config: GenericListConfig = {
    path: 'backend_users',
    stateKey: 'backendUser',
    newButtonLabel: 'Novo Usuário',
    headerLabel: 'Editar Usuário',
    itemsToShow: [
      { name: 'name', title: 'Nome', type: ItemType.text },
      { name: 'email', title: 'Email', type: ItemType.text },
      { name: 'role', title: 'Acesso', type: ItemType.text },
      { name: 'phone', title: 'Telefone', type: ItemType.text },
      { name: 'picture', title: 'Foto', type: ItemType.image },
      {
        name: ['companies', 'name'],
        title: 'Cliente Corporativo',
        type: ItemType.array,
      },
    ],
  };

  const { userLoggedIn } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const canDeleteUser = (item: any) => {
    return hasRole(userLoggedIn, item.role) && userLoggedIn.sub !== item._id;
  };

  const itemsToShow = config.itemsToShow.filter(
    (x) => x.name[0] !== 'companies' || userLoggedIn.role === 'sa',
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <GenericList
            config={{ ...config, itemsToShow }}
            actions={backendUserActions}
            canDelete={canDeleteUser}
          />
        }
      />
      <Route
        path="/new"
        element={
          <GenericNew
            config={config}
            actions={backendUserActions}
            Form={Form}
            defaultValues={backendUser}
          />
        }
      />
      <Route
        path="/:id/edit"
        element={<Edit config={config} actions={backendUserActions} />}
      />
      <Route path="/:id/logs" element={<Logs />} />
    </Routes>
  );
};

export default BackendUsersRoutes;
