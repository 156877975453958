import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface LoadingProps {
  size?: SizeProp;
}

const Loading: React.FC<LoadingProps> = ({ size }) => {
  size = size || '3x';
  return (
    <div className="loading">
      <FontAwesomeIcon
        icon={faCircleNotch}
        spin={true}
        size={size}
        aria-hidden="true"
      />
      <span className="text sr-only">Loading...</span>
    </div>
  );
};

export { Loading };
