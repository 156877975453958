import React, { useEffect } from 'react';
import { Button, Alert, Row, Col, Card } from 'react-bootstrap';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
//import PropTypes from 'prop-types';
//import { StoreState } from '../../types';

export interface GenericFormProps {
  initialValues: { [key: string]: any };
  path: string;
  onSave: (data: any) => any;
  fetching: boolean;
  success: boolean;
  requestError: string;
  //pristine: any;
}

export interface GenericFormPropsExtended extends GenericFormProps {
  form: UseFormReturn<any>;
}

const GenericForm: React.FC<GenericFormPropsExtended> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    props.form.reset(props.initialValues);
  }, [props.form, props.initialValues]);

  const onSubmit = (data: GenericFormProps) => {
    dispatch(props.onSave({ ...data }));
  };

  const { requestError, children, path } = props;

  const pristine = false;

  if (props.success) {
    return <Navigate to={'/' + path} />;
  }

  if (Object.keys(props.form.formState.errors).length > 0) {
    console.warn(props.form.formState.errors); // FIXME: remove this line
  }

  return (
    <form onSubmit={props.form.handleSubmit(onSubmit)}>
      {requestError && (
        <Alert variant="danger">
          <strong>Oops!</strong> {requestError}
        </Alert>
      )}

      <Row>
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>{children}</Card.Body>
            <Card.Footer className="buttons-separation">
              <Button
                type="submit"
                variant="primary"
                disabled={pristine || props.fetching}
              >
                Salvar
              </Button>
              <Link to={'/' + path} className="btn btn-danger">
                Cancelar
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default GenericForm;
