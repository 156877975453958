import { AxiosError } from 'axios';
import { axiosAuth as axios, axiosErrorMessage } from '../helpers';
import { Record, StoreState } from '../types';
import { signout } from './auth';

export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_ONE = 'FETCH_ONE';
export const ERROR = 'ERROR';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const RESTORE = 'RESTORE';
export const REQUEST_LOADING = 'REQUEST_LOADING';
export const REQUEST_NOT_LOADING = 'REQUEST_NOT_LOADING';
export const CLEAR_STATUS = 'CLEAR_STATUS';
export const SET_SEARCH = 'SET_SEARCH';
export const RESET_LAST_ID = 'RESET_LAST_ID';

export const globalParams = function (
  filterCompany: string,
  state: StoreState,
) {
  return {
    company: filterCompany ? state.auth.currentCompany : undefined,
  };
};

export default function genericRest(
  prefix: string,
  apiPath: string,
  { limit = 30, filterCompany = true }: any = {},
) {
  const actions = {
    fetchAll: function (page = 0, queryParams = {}, deleted = false) {
      return async (dispatch: Function, getState: any) => {
        try {
          dispatch(actions.requestLoading());
          const response = await axios().get(apiPath, {
            params: {
              ...globalParams(filterCompany, getState()),
              ...queryParams,
              skip: page * limit,
              deleted,
            },
          });
          dispatch(actions.requestNotLoading());
          dispatch({
            type: prefix + '/' + FETCH_ALL,
            payload: {
              ...response.data,
              page,
            },
          });
        } catch (error) {
          const err = error as AxiosError;
          if (err.response && err.response.status === 401) {
            dispatch(signout('Session expired')); // Token expired or User deleted
          } else {
            dispatch(actions.error(axiosErrorMessage(err)));
          }
        }
      };
    },
    fetchOne(id: string) {
      return async (dispatch: Function) => {
        try {
          dispatch(actions.requestLoading());
          const response = await axios().get(apiPath + '/' + id);
          dispatch(actions.requestNotLoading());
          dispatch({
            type: prefix + '/' + FETCH_ONE,
            payload: response.data,
          });
        } catch (error) {
          const err = error as AxiosError;
          dispatch(actions.error(axiosErrorMessage(err)));
        }
      };
    },
    error(error: AxiosError) {
      return {
        type: prefix + '/' + ERROR,
        payload: error,
      };
    },
    requestLoading() {
      return {
        type: prefix + '/' + REQUEST_LOADING,
      };
    },
    requestNotLoading() {
      return {
        type: prefix + '/' + REQUEST_NOT_LOADING,
      };
    },
    clearStatus() {
      return {
        type: prefix + '/' + CLEAR_STATUS,
      };
    },
    create(record: {}) {
      return async (dispatch: Function) => {
        try {
          dispatch(actions.requestLoading());
          const response = await axios().post(apiPath, record);
          dispatch(actions.requestNotLoading());
          return dispatch({
            type: prefix + '/' + CREATE,
            payload: response.data._id,
          });
        } catch (error) {
          const err = error as AxiosError;
          dispatch(actions.error(axiosErrorMessage(err)));
        } finally {
          dispatch(actions.clearStatus());
        }
      };
    },
    update(record: Record) {
      return async (dispatch: Function) => {
        try {
          dispatch(actions.requestLoading());
          const response = await axios().put(
            `${apiPath}/${record._id}`,
            record,
          );
          dispatch(actions.requestNotLoading());
          return dispatch({
            type: prefix + '/' + UPDATE,
            payload: response.data._id,
          });
        } catch (error) {
          const err = error as AxiosError;
          dispatch(actions.error(axiosErrorMessage(err)));
        } finally {
          dispatch(actions.clearStatus());
        }
      };
    },
    delete(id: string) {
      return async (dispatch: Function) => {
        try {
          dispatch(actions.requestLoading());
          await axios().delete(apiPath + '/' + id);
          dispatch(actions.requestNotLoading());
          return dispatch({ type: prefix + '/' + DELETE });
        } catch (error) {
          const err = error as AxiosError;
          dispatch(actions.error(axiosErrorMessage(err)));
        } finally {
          dispatch(actions.clearStatus());
        }
      };
    },
    restore(id: string) {
      return async (dispatch: Function) => {
        try {
          dispatch(actions.requestLoading());
          await axios().put(apiPath + '/restore/' + id);
          dispatch(actions.requestNotLoading());
          dispatch({ type: prefix + '/' + RESTORE });
        } catch (error) {
          const err = error as AxiosError;
          dispatch(actions.error(axiosErrorMessage(err)));
        } finally {
          dispatch(actions.clearStatus());
        }
      };
    },
    setSearch(text: string) {
      return async (dispatch: Function) => {
        dispatch({
          type: prefix + '/' + SET_SEARCH,
          payload: text,
        });
      };
    },
    resetLastId() {
      return {
        type: prefix + '/' + RESET_LAST_ID,
      };
    },
  };
  return actions;
}
