import { axiosAuth as axios } from '../../helpers/index';
import {
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  UPLOAD_LOADING,
  UPLOAD_CLEAR,
  // UPLOAD_NOT_LOADING,
} from './_types';
import FormData from 'form-data';

const API_URL = process.env.REACT_APP_API_URL;

export function uploadImage(image: any[]) {
  return async (dispatch: Function) => {
    dispatch(uploadLoading());

    try {
      let data = new FormData();
      data.append('file', image[0]);

      const response = await axios()({
        url: `${API_URL}/upload`,
        data: data,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return dispatch({
        type: UPLOAD_SUCCESS,
        payload: {
          url: response.data.url,
        },
      });
    } catch (err) {
      dispatch({
        type: UPLOAD_ERROR,
        payload: err,
      });
    }
  };
}

export function clearUploadImage() {
  return async (dispatch: Function) => {
    return dispatch({
      type: UPLOAD_CLEAR,
    });
  };
}

function uploadLoading() {
  return {
    type: UPLOAD_LOADING,
  };
}

// function uploadNotLoading() {
//   return {
//     type: UPLOAD_NOT_LOADING
//   };
// }
