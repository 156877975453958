import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faApple,
  faAndroid,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';

export const RenderPlatforms: React.FC = (platforms: any) => {
  return (
    platforms && (
      <span>
        {platforms.ios && (
          <FontAwesomeIcon
            icon={faApple}
            style={{ marginRight: 5, color: '#999999' }}
          />
        )}
        {platforms.android && (
          <FontAwesomeIcon
            icon={faAndroid}
            style={{ marginRight: 5, color: '#a4c639' }}
          />
        )}
        {platforms.web && (
          <FontAwesomeIcon
            icon={faWindows}
            style={{ marginRight: 5, color: '#aaccff' }}
          />
        )}
      </span>
    )
  );
};
