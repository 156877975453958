import React from 'react';
import { Route, Routes } from 'react-router-dom';
import IntroductionsRoutes from '../components/introductions';
import NewsRoutes from '../components/news';
import MediaRoutes from '../components/media';
import ServiceChannelRoutes from '../components/service_channels';
import ToolRoutes from '../components/tools';
import TabRoutes from '../components/tabs';
import TermsOfServiceRoutes from '../components/terms_of_service';
import LuckyNumberRoutes from '../components/lucky_number';
import HomeLinksRoutes from '../components/home_links';

export const CrudRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/introductions/*" element={<IntroductionsRoutes />} />
      <Route path="/news/*" element={<NewsRoutes />} />
      <Route path="/media/*" element={<MediaRoutes />} />
      <Route path="/service_channels/*" element={<ServiceChannelRoutes />} />
      <Route path="/tools/*" element={<ToolRoutes />} />
      <Route path="/tabs/*" element={<TabRoutes />} />
      <Route path="/home_links/*" element={<HomeLinksRoutes />} />
      <Route path="/terms_of_service/*" element={<TermsOfServiceRoutes />} />
      <Route path="/lucky_number/*" element={<LuckyNumberRoutes />} />
    </Routes>
  );
};
