import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InfiniteScroll } from '../common';
import { Log } from '../../types/Log';

interface ListProps {
  fetching: boolean;
  items: Log[];
  count: number;
  loadMore: () => void;
  fromModal?: boolean;
  error?: any;
}

const List: React.FC<ListProps> = (props) => {
  const [fullDiff, setFullDiff] = useState(false);

  // static propTypes = {
  //   fetching: PropTypes.bool.isRequired,
  //   items: PropTypes.arrayOf(PropTypes.object).isRequired,
  //   count: PropTypes.number.isRequired,
  //   loadMore: PropTypes.func.isRequired,
  //   fromModal: PropTypes.bool,
  // };

  const renderRows = (items: Log[]) => {
    const { fromModal } = props;

    if (!items.length) {
      return (
        <tr>
          <td colSpan={fromModal ? 4 : 6}>Nenhum log encontrado</td>
        </tr>
      );
    }

    return items.map((log: Log) => {
      const user = log.backend_user;
      return (
        <tr key={log._id}>
          <td>{log.activity}</td>
          <td>
            <div
              style={{
                overflow: 'auto',
                maxWidth: 300,
                maxHeight: 100,
                cursor: 'pointer',
              }}
              onClick={() => setFullDiff(!fullDiff)}
            >
              {fullDiff || !log.previous
                ? JSON.stringify(log.previous)
                : Object.keys(log.previous).join(', ')}
            </div>
          </td>
          {!fromModal && (
            <td>
              {log.collection_name}
              {log.subdocument_path ? ' / ' + log.subdocument_path : ''}
            </td>
          )}
          {!fromModal && (
            <td title={log.doc}>
              {log.doc_name || log.doc}{' '}
              {log.subdocument_name ? ' / ' + log.subdocument_name : ''}
            </td>
          )}
          <td title={user ? user._id : ''}>
            {user ? (
              <Link to={`/backend_users/${user._id}/edit`}>
                {user.email || '-'}
              </Link>
            ) : (
              '(removido)'
            )}
          </td>
          <td>{log.ip || '-'}</td>
          <td>{moment(log.date).format('DD/MM/YYYY HH:mm')}</td>
        </tr>
      );
    });
  };

  const { fetching, items, count, loadMore, fromModal, error } = props;

  if (error) {
    return (
      <div>
        <br />
        <Alert variant="danger">
          <strong>Oops!</strong> {error}
        </Alert>
      </div>
    );
  }

  return (
    <InfiniteScroll
      fetching={fetching}
      length={items.length}
      count={count}
      loadMore={loadMore}
      useWindow={!fromModal}
    >
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Atividade</th>
              <th>Versão Anterior</th>
              {!fromModal && <th>Tipo</th>}
              {!fromModal && <th>Registro</th>}
              <th>Email do Usuário</th>
              <th>Endereço IP</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>{renderRows(items)}</tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};

export default List;
