import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectInput } from './SelectInput';
import { SelectMultipleInput } from './SelectMultipleInput';
import { companyActions } from '../../../actions';
import { FormControl } from 'react-bootstrap';
import { AuthAdminState, CompanyAdminState, StoreState } from '../../../types';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

interface CompanySelectProps {
  //change: (form: string, field: string, value: any) => void;
  label: string;
  multiple?: boolean;
  meta: ControllerFieldState;
  input: ControllerRenderProps<any, any>;
  className?: string;
}

export const CompanySelect: React.FC<CompanySelectProps> = (props) => {
  const { all: companies, fetching } = useSelector<
    StoreState,
    CompanyAdminState
  >((state) => state.company);
  const { currentCompany } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(companyActions.fetchAll());
    // FIXME
    // const { meta, input } = props;
    // if (!input.value && currentCompany) {
    //   dispatch(change(meta.form, input.name, currentCompany));
    // }
  }, [props, dispatch, currentCompany]);

  const { multiple, ...otherProps } = props;

  if (fetching) {
    return <div>Loading</div>;
  }

  if (!companies) {
    return <div>No Data</div>;
  }

  const options = companies.map((company: any) => ({
    value: company._id,
    label: company.name,
  }));
  if (companies.length === 1) {
    return <FormControl {...otherProps.input} type="hidden" />;
  }
  if (multiple) {
    return <SelectMultipleInput {...otherProps} options={options} />;
  }
  return <SelectInput {...otherProps} options={options} />;
};
