import { Route, Routes } from 'react-router-dom';
import GenericList from './GenericList';
import { GenericEdit } from './GenericEdit';
import { GenericNew } from './GenericNew';
import { Actions, GenericListConfig } from '../../types';
import { GenericFormProps } from './GenericForm';

interface GenericRoutesProps {
  config: GenericListConfig;
  actions: Actions;
  defaultValues: any;
  Form: React.FC<GenericFormProps>;
}

export const GenericRoutes: React.FC<GenericRoutesProps> = ({
  config,
  actions,
  defaultValues,
  Form,
}) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<GenericList config={config} actions={actions} />}
      />
      <Route
        path="/new"
        element={
          <GenericNew
            config={config}
            actions={actions}
            Form={Form}
            defaultValues={defaultValues}
          />
        }
      />
      <Route
        path="/:id/edit"
        element={<GenericEdit config={config} actions={actions} Form={Form} />}
      />
    </Routes>
  );
};
