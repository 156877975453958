import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeaderCompanies from './HeaderCompanies';
import { AuthAdminState, StoreState } from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOut, faUser } from '@fortawesome/free-solid-svg-icons';

const { Text } = Navbar;

const NavHeader: React.FC = () => {
  const { authenticated, userLoggedIn: user } = useSelector<
    StoreState,
    AuthAdminState
  >((state) => state.auth);

  const renderAuth = () => {
    return (
      <div className="navbar-right">
        <Text className="me-3">
          <FontAwesomeIcon icon={faUser} className="me-2" />
          <strong>{user.email}</strong>
        </Text>
        <Text>
          <Link to="/auth/signout" className="navbar-link">
            <FontAwesomeIcon icon={faSignOut} className="me-2" /> Sair
          </Link>
        </Text>
      </div>
    );
  };

  if (!authenticated) {
    return null;
  }

  return (
    <header>
      <Navbar fixed="top" variant="dark">
        <Container fluid>
          <div className="d-flex flex-row align-items-center">
            <Navbar.Brand>
              <Nav.Link href="/">InterAdmin | CDF Digital</Nav.Link>
            </Navbar.Brand>
            <div className="navbar-left">
              <HeaderCompanies />
            </div>
          </div>
          {renderAuth()}
        </Container>
      </Navbar>
    </header>
  );
};

export default NavHeader;
