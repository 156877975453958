import {
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  UPLOAD_LOADING,
  UPLOAD_CLEAR,
} from '../../actions/upload/_types';

const INITIAL_STATE = {
  url: null,
  fetching: false,
  error: null,
};

export default function upload(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case UPLOAD_SUCCESS:
      return {
        ...state,
        url: action.payload.url,
        fetching: false,
      };
    case UPLOAD_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case UPLOAD_LOADING:
      return {
        ...state,
        fetching: true,
      };
    case UPLOAD_CLEAR:
      return {
        ...state,
        url: null,
        fetching: false,
        error: null,
      };
    default:
      return state;
  }
}
