import React, { useState } from 'react';
import BaseInfiniteScroll from 'react-infinite-scroller';
import { Loading } from './Loading';

interface InfiniteScrollProps {
  fetching: boolean;
  length: number;
  count: number;
  loadMore: () => void;
  useWindow?: boolean;
}

export const InfiniteScroll: React.FC<InfiniteScrollProps> = (props) => {
  const [fetchingMore, setFetchingMore] = useState(false);

  const handleLoadMore = () => {
    const { fetching, loadMore } = props;
    if (!fetching) {
      setFetchingMore(true);
      loadMore();
    }
  };

  const { fetching, children, length, count, useWindow } = props;

  if (fetching && !fetchingMore) {
    return <Loading />; // Initial load, but not page loads
  }

  return (
    <BaseInfiniteScroll
      initialLoad={false}
      loadMore={handleLoadMore.bind(this)}
      hasMore={length < count}
      threshold={150}
      loader={
        <div className="loader" key="loader">
          Loading ...
        </div>
      }
      useWindow={useWindow}
    >
      {/* @ts-ignore */}
      {children}
    </BaseInfiniteScroll>
  );
};
