import React from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ReactSelectOption as Option } from '../../../types';
import { ErrorOrHelp } from './ErrorOrHelp';

function renderOptions(options: Option[]) {
  return options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));
}

interface SelectInputProps {
  input: ControllerRenderProps<any, any>;
  label?: string;
  disabled?: boolean;
  options: Option[];
  className?: string;
  meta: ControllerFieldState;
  children?: React.ReactNode;
  help?: string;
}

export const SelectInput: React.FC<SelectInputProps> = ({
  input,
  label,
  disabled,
  options,
  className,
  meta: { error },
  children,
  help,
}) => {
  return (
    <FormGroup className={['form-group', className].join(' ')}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        {...input}
        disabled={disabled}
        className={error ? 'is-invalid' : ''}
      >
        <option />
        {Array.isArray(options) ? renderOptions(options) : children}
      </Form.Select>
      <ErrorOrHelp error={error} help={help} />
    </FormGroup>
  );
};
