import React from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ErrorOrHelp } from './ErrorOrHelp';

interface TextareaInputProps {
  input: ControllerRenderProps<any, any>;
  label?: string;
  type: any;
  meta: ControllerFieldState;
  help?: string;
  className?: string;
}

export const TextareaInput: React.FC<TextareaInputProps> = ({
  input,
  label,
  //type,
  meta: { error },
  help,
  className,
}) => {
  return (
    <FormGroup className={['form-group', className].join(' ')}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        {...input}
        placeholder={label}
        className={error ? 'is-invalid' : ''}
      />
      <ErrorOrHelp error={error} help={help} />
    </FormGroup>
  );
};
