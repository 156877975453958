import { CompanyAdmin } from '../types';
import { commonProps } from './commonProps';

export const company: Omit<CompanyAdmin, '_id'> = {
  ...commonProps,
  name: '',
  externalId: '',
  smsSignature: '',
  takiUsername: '',
  takiPassword: '',
};
