import axios from 'axios';

export const axiosAuth = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      authorization: localStorage.getItem('admin-token'),
    },
  });

export function axiosErrorMessage(err: any) {
  if (err.response && err.response.data) {
    const { data } = err.response;
    if (data.error) {
      return data.error;
    }
    if (data.MENSAGEM_RETORNO) {
      return data.MENSAGEM_RETORNO;
    }
  }
  return err.message;
}
