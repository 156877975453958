export const normalizations = {
  telephone: (value: string) => {
    if (!value) {
      return value;
    }

    let onlyNumbers = value.replace(/\D/g, '');
    if (onlyNumbers.length > 11) {
      onlyNumbers = onlyNumbers.slice(0, 11);
    }

    return onlyNumbers
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2');
  },
  document: (value: string) => {
    let onlyNumbers = value.replace(/\D/g, '');

    if (onlyNumbers.length > 14) {
      onlyNumbers = onlyNumbers.slice(0, 14);
    }

    if (onlyNumbers.length < 12) {
      // is CPF
      return onlyNumbers
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }

    return onlyNumbers
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2');
  },
};
