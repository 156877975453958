import { IntroductionAdmin } from '../types';
import { commonProps } from './commonProps';

export const introduction: Omit<IntroductionAdmin, '_id'> = {
  ...commonProps,
  text: '',
  details: '',
  backgroundMobile: '',
  background: '',
  order: 0,
  platforms: {
    ios: false,
    android: false,
    web: false,
  },
};
