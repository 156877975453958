import React from 'react';
import { NavLink } from 'react-router-dom';

interface RouterLinkProps {
  to: string | {};
  children: any;
  eventKey?: string;
}

const RouterLink: React.FC<RouterLinkProps> = ({ to, children }) => {
  return (
    <li className="nav-item">
      <NavLink to={to} className="nav-link">
        {children}
      </NavLink>
    </li>
  );
};

export { RouterLink };
