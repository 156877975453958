import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { configureStore } from './services/config_store';
import App from './components/app';
import { GoogleOAuthProvider } from '@react-oauth/google';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={configureStore()}>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string}
    >
      <App />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById('root'),
);
