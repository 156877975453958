import { combineReducers } from 'redux';
import {
  AUTH_SIGNIN,
  AUTH_SIGNOUT,
  AUTH_ERRORS,
  AUTH_CURRENTLY_LOGGED_IN,
  AUTH_PASSWORDLESS,
  AUTH_PASSWORDLESS_ERRORS,
  AUTH_PASSWORDLESS_SENDING,
  AUTH_PASSWORDLESS_LOADING,
  AUTH_PASSWORDLESS_NOT_LOADING,
  AUTH_SET_CURRENT_COMPANY,
} from '../../actions/auth/_types';

export default combineReducers({
  authenticated: (state = false, action) => {
    switch (action.type) {
      case AUTH_SIGNIN:
      case AUTH_CURRENTLY_LOGGED_IN:
        return true;
      case AUTH_SIGNOUT:
        return false;
      default:
        return state;
    }
  },
  userLoggedIn: (state = {}, action) => {
    switch (action.type) {
      case AUTH_CURRENTLY_LOGGED_IN:
        return action.payload;
      case AUTH_SIGNOUT:
        return {};
      default:
        return state;
    }
  },
  errors: (state = null, action) => {
    switch (action.type) {
      case AUTH_ERRORS:
      case AUTH_SIGNOUT:
        return action.payload;
      case AUTH_SIGNIN:
      case AUTH_CURRENTLY_LOGGED_IN:
        return null;
      default:
        return state;
    }
  },
  passwordless: combineReducers({
    active: (state = false, action) => {
      switch (action.type) {
        case AUTH_PASSWORDLESS:
          return true;
        default:
          return false;
      }
    },
    errors: (state = null, action) => {
      switch (action.type) {
        case AUTH_PASSWORDLESS_ERRORS:
          return action.payload;
        case AUTH_PASSWORDLESS:
          return null;
        default:
          return state;
      }
    },
    sending: (state = false, action) => {
      switch (action.type) {
        case AUTH_PASSWORDLESS_SENDING:
          return true;
        case AUTH_PASSWORDLESS:
        case AUTH_PASSWORDLESS_ERRORS:
          return false;
        default:
          return state;
      }
    },
    loading: (state = false, action) => {
      switch (action.type) {
        case AUTH_PASSWORDLESS_LOADING:
          return true;
        case AUTH_PASSWORDLESS_NOT_LOADING:
          return false;
        default:
          return state;
      }
    },
  }),
  currentCompany: (state = null, action) => {
    switch (action.type) {
      case AUTH_SET_CURRENT_COMPANY:
        localStorage.setItem('currentCompany', action.payload);
        return action.payload;
      default:
        return state;
    }
  },
});
