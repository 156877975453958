import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../common';
import Form from './Form';
import Tabs from './tabs';
import {
  Actions,
  BackendUserAdminState,
  GenericListConfig,
  StoreState,
} from '../../types';
import { useParams } from 'react-router-dom';

interface EditProps {
  config: GenericListConfig;
  actions: Actions;
}

export const Edit: React.FC<EditProps> = ({ config: { path }, actions }) => {
  const { id } = useParams<{ id: string }>();

  const { record, fetching, success, requestError } = useSelector<
    StoreState,
    BackendUserAdminState
  >((state) => state.backendUser);

  const dispatch = useDispatch();

  const { fetchOne, update } = actions;

  useEffect(() => {
    if (id !== undefined) {
      dispatch(fetchOne(id));
    }
  }, [dispatch, fetchOne, id]);

  if (fetching || !record || record._id !== id) {
    return <Loading />;
  }

  return (
    <div className={`${path}-edit`}>
      <Tabs modelUrl={path} parentId={id} />

      <Form
        initialValues={record}
        fetching={fetching}
        success={success}
        requestError={requestError}
        onSave={update}
        path={path}
      />
    </div>
  );
};
