import React, { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  FormControl,
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap';
import Imgix from 'react-imgix';
import { isValidLink, parseUrl } from '../../../helpers/index';
import UploadModal from '../../upload/UploadModal';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { ErrorOrHelp } from './ErrorOrHelp';

interface FileInputProps {
  setValue: Function;
  input: ControllerRenderProps<any, any>;
  label: string;
  disabled?: boolean;
  className?: string;
  meta: ControllerFieldState;
  help?: string;
}

export const FileInput: React.FC<FileInputProps> = (props) => {
  const [modal, setModal] = useState(false);

  const showModal = () => {
    setModal(true);
  };

  const hideModal = () => {
    setModal(false);
  };

  const onUpload = (url: string) => {
    props.setValue(input.name, parseUrl(url));
    setModal(false);
  };

  const {
    input,
    label,
    disabled,
    className,
    meta: { error },
    help,
  } = props;

  return (
    <div className="input-file">
      <Row>
        <Col md={8}>
          <FormGroup
            className={['form-group', className].join(' ')}
            controlId={input.name}
          >
            <Form.Label>{label}</Form.Label>
            <InputGroup className={error ? 'is-invalid' : ''}>
              <FormControl
                {...input}
                placeholder={label}
                disabled={disabled}
                className={error ? 'is-invalid' : ''}
              />
              <Button
                variant="outline-secondary"
                id="search"
                onClick={showModal}
              >
                Procurar...
              </Button>
            </InputGroup>
            <ErrorOrHelp error={error} help={help} />
          </FormGroup>
        </Col>
        <Col md={4}>
          {input.value && isValidLink(input.value) && !error && (
            <a
              className="img-wrapper"
              href={parseUrl(input.value)}
              target="_blank"
              rel="noreferrer"
            >
              <Imgix
                src={parseUrl(input.value)}
                className="img-responsive"
                width={120}
                height={80}
                imgixParams={{
                  fit: 'false',
                }}
              />
            </a>
          )}
        </Col>
      </Row>

      <UploadModal
        show={modal}
        handleHideModal={hideModal}
        handleOnUpload={onUpload}
      />
    </div>
  );
};
