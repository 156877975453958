import React /*, { useEffect }*/ from 'react';
//import _ from 'lodash';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';

interface ListSearchProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

export const ListSearch: React.FC<ListSearchProps> = (props) => {
  //let handleSearch = () => {};

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   handleSearch = _.debounce(() => props.onSearch(), 200);
  // }, [props.onSearch]);

  const handleChange = (value: string) => {
    props.onChange(value); // change value
    //handleSearch(); // fetch search results
  };

  const { placeholder, value } = props;

  return (
    <div className="has-feedback">
      <Form.Group controlId="formSearch">
        <InputGroup className="input-search">
          <Form.Control
            type="text"
            name="search"
            placeholder={`${placeholder}...`}
            onChange={(e) => handleChange(e.target.value)}
            value={value || ''}
          />
          <InputGroup.Text>
            {value ? (
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => handleChange('')}
              />
            ) : (
              <FontAwesomeIcon icon={faSearch} />
            )}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
    </div>
  );
};
