import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from '../common';
import {
  Actions,
  GenericListConfig,
  RecordAdminState,
  StoreState,
} from '../../types';

interface GenericEditProps {
  config: GenericListConfig;
  Form: React.FC<any>;
  actions: Actions;
}

export const GenericEdit: React.FC<GenericEditProps> = ({
  config: { path, stateKey, headerLabel },
  Form,
  actions,
}) => {
  const { id } = useParams<{ id: string }>();

  const { record, fetching, success, requestError } = useSelector<
    StoreState,
    RecordAdminState<any>
  >((state) => state[stateKey]);

  const dispatch = useDispatch();

  const { fetchOne, update } = actions;

  useEffect(() => {
    if (id) {
      dispatch(fetchOne(id));
    }
  }, [dispatch, fetchOne, id]);

  if (fetching || !record || record._id !== id) {
    return <Loading />;
  }

  return (
    <div className={`${path}-edit`}>
      <h3>{headerLabel}</h3>
      <Form
        initialValues={record}
        fetching={fetching}
        success={success}
        requestError={requestError}
        onSave={update}
        path={path}
      />
    </div>
  );
};
