import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { companyActions, setCurrentCompany } from '../../actions';
import { CompanyAdminState, StoreState } from '../../types';
import { Form } from 'react-bootstrap';

interface HeaderCompaniesProps {}

const HeaderCompanies: React.FC<HeaderCompaniesProps> = () => {
  const dispatch = useDispatch();
  const { all: companies } = useSelector<StoreState, CompanyAdminState>(
    (state) => state.company,
  );
  const currentCompany = useSelector(
    (state: StoreState) => state.auth.currentCompany,
  );

  useEffect(() => {
    dispatch(companyActions.fetchAll());
  }, [dispatch]);

  useEffect(() => {
    if (companies.length) {
      // In case company is deleted / seeded with different ID
      if (
        currentCompany &&
        !companies.find((company) => company._id === currentCompany)
      ) {
        // @ts-ignore
        dispatch(setCurrentCompany(null));
      }
      if (companies.length === 1) {
        dispatch(setCurrentCompany(companies[0]._id));
      }
    }
  }, [companies, currentCompany, dispatch]);

  if (!companies.length) {
    return null; //<Loading size="2x" />;
  }

  if (companies.length === 1) {
    return <span className="navbar-text">{companies[0].name}</span>;
  }

  return (
    <Form.Select
      className="form-control"
      placeholder="Clientes Corporativos"
      onChange={(e) => dispatch(setCurrentCompany(e.target.value))}
      value={currentCompany || undefined}
    >
      <option value="">Todos Clientes Corporativos</option>
      {companies.map((company) => {
        return (
          <option key={company._id} value={company._id}>
            {company.name}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default HeaderCompanies;
