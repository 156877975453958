import React from 'react';
import { companyActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { GenericListConfig, ItemType } from '../../types';
import { company } from '../../defaultValues';
import Form from './Form';

const config: GenericListConfig = {
  path: 'companies',
  stateKey: 'company',
  newButtonLabel: 'Novo Cliente Corporativo',
  headerLabel: 'Editar Cliente Corporativo',
  itemsToShow: [
    { name: 'externalId', title: 'ID Externo', type: ItemType.number },
    { name: 'name', title: 'Nome', type: ItemType.text },
    { name: 'takiUsername', title: 'Taki Username', type: ItemType.text },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={companyActions}
      defaultValues={company}
      Form={Form}
    />
  );
};

export default Index;
