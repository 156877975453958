import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import ReduxThunk from 'redux-thunk';
import reducers from '../reducers';
import { signinJwt, setCurrentCompany } from '../actions';

export const history = createBrowserHistory();

export function configureStore(initialState = {}) {
  // Create the store with middlewares
  const middlewares = [ReduxThunk];

  const store = createStore(
    reducers,
    initialState,
    applyMiddleware(...middlewares),
  );

  // Add user to state if they already have a token
  const token = localStorage.getItem('admin-token');
  if (token && token !== 'null') {
    store.dispatch(signinJwt(token));
  }
  const currentCompany = localStorage.getItem('currentCompany');
  if (currentCompany && currentCompany !== 'null') {
    store.dispatch(setCurrentCompany(currentCompany));
  }
  return store;
}
