import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { signout } from '../../actions';
import { AuthAdminState, StoreState } from '../../types';

const Signout: React.FC = () => {
  const { authenticated } = useSelector<StoreState, AuthAdminState>(
    (state) => state.auth,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signout());
  }, [dispatch]);

  if (!authenticated) {
    return <Navigate to="/auth" />;
  }

  return (
    <Container>
      <h1>Signing out...</h1>
      <p>Please wait.</p>
    </Container>
  );
};

export default Signout;
