import { combineReducers } from 'redux';
import genericRestReducer from './generic_rest';
import uploadReducer from './upload';
import authReducer from './auth';
import {
  COMPANY,
  BACKEND_USER,
  LOG,
  INTRODUCTION,
  NEWS,
  MEDIA,
  SERVICE_CHANNEL,
  TOOL,
  TAB,
  HOME_LINK,
  TERMS_OF_SERVICE,
  LUCKY_NUMBER,
} from '../actions/types';

export default combineReducers({
  upload: uploadReducer,
  auth: authReducer,
  company: genericRestReducer(COMPANY),
  backendUser: genericRestReducer(BACKEND_USER),
  log: genericRestReducer(LOG),
  introduction: genericRestReducer(INTRODUCTION),
  news: genericRestReducer(NEWS),
  media: genericRestReducer(MEDIA),
  serviceChannel: genericRestReducer(SERVICE_CHANNEL),
  tool: genericRestReducer(TOOL),
  tab: genericRestReducer(TAB),
  homeLink: genericRestReducer(HOME_LINK),
  termsOfService: genericRestReducer(TERMS_OF_SERVICE),
  luckyNumber: genericRestReducer(LUCKY_NUMBER),
});
