import React from 'react';
import { mediaActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { GenericListConfig, ItemType } from '../../types';
import { mediaItem } from '../../defaultValues';
import Form from './Form';

const config: GenericListConfig = {
  path: 'media',
  stateKey: 'media',
  newButtonLabel: 'Nova Mídia',
  headerLabel: 'Editar Mídia',
  itemsToShow: [
    { name: 'order', title: 'Ordem', type: ItemType.number },
    { name: 'title', title: 'Título', type: ItemType.number },
    { name: 'image', title: 'Imagem', type: ItemType.image },
    { name: 'publishedAt', title: 'Publicação', type: ItemType.date },
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={mediaActions}
      defaultValues={mediaItem}
      Form={Form}
    />
  );
};

export default Index;
