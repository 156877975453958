import React from 'react';
import { termsOfServiceActions } from '../../actions';
import { GenericRoutes } from '../_generic_crud';
import { GenericListConfig, ItemType } from '../../types';
import Form from './Form';
import { termsOfService } from '../../defaultValues';

const config: GenericListConfig = {
  path: 'terms_of_service',
  stateKey: 'termsOfService',
  newButtonLabel: 'Novo Termos de Serviço',
  headerLabel: 'Editar Termos de Serviço',
  itemsToShow: [
    {
      name: ['company', 'name'],
      title: 'Cliente Corporativo',
      type: ItemType.object,
    },
  ],
};

const Index: React.FC = () => {
  return (
    <GenericRoutes
      config={config}
      actions={termsOfServiceActions}
      defaultValues={termsOfService}
      Form={Form}
    />
  );
};

export default Index;
