import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldError } from 'react-hook-form';

interface ErrorOrHelpProps {
  error?: FieldError;
  help?: string;
}

export const ErrorOrHelp: React.FC<ErrorOrHelpProps> = ({ error, help }) => {
  return error ? (
    <Form.Control.Feedback type="invalid">
      {error.message}
    </Form.Control.Feedback>
  ) : help ? (
    <Form.Text className="text-muted">{help}</Form.Text>
  ) : (
    <></>
  );
};
